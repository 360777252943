import $ from "jquery";
import lightGallery from 'lightgallery';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import 'lg-video'
$(document).ready(() => {

  if ($('body').hasClass('LiveWebinarHolderHighCouncil') || $('body').hasClass('LibraryHolder')  || $('body').hasClass('LiveWebinarHolder')
  || $('body').hasClass('LiveWebinarHolderJournal')
  || $('body').hasClass('PortfolioPage')
  ) {

  }
  else {

    if ($('body').hasClass('page-30815')){
    }
    else{
      $("#gallery").lightGallery({
        selector: '.image__link'
      });


      $("#lightboxspeaker").lightGallery({
      selector: '.image__link'
      });
    }
  }


  if ($('body').hasClass('first-ladies-theme') || $('body').hasClass('PortfolioPage') || $('body').hasClass('Album')
  || $('body').hasClass('page-37691')

) {

    jQueryBridget('masonry', Masonry, $);

    // now you can use $().masonry()
    $('.grid').masonry({
      itemSelector: '.grid-item',
      percentPosition: true,
      columnWidth: '.grid-sizer'
    });

    // init Masonry
    var $grid = $('.grid').masonry({
      columnWidth: 200,
      itemSelector: '.grid-item',
      percentPosition: true,
      columnWidth: '.grid-sizer',
      gutter: 1
    });
  }
});
