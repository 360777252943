import validate from 'jquery-validation';
import $ from "jquery";

// These are used at multiple places.
const $divergentCheckbox = $("input[id$='_DivergentInvoiceData']");

// -----------------------------------------------------------------------
//
// Rules & error messages for forms
//
// -----------------------------------------------------------------------

// Rules used for divergent invoice data;
// These are required if the checkbox is checked.
const divergentInvoiceDataRules = {
  AvdrConditions:{
    required: true
  },
  InvoiceEmail: {
    email: true,
    required: $divergentCheckbox
  },
  InvoiceAscription: {
    required: $divergentCheckbox
  },
  InvoiceAddress: {
    required: $divergentCheckbox
  },
  InvoicePostalCode: {
    required: $divergentCheckbox
  },
  InvoiceCity: {
    required: $divergentCheckbox
  }
};

const registerFormRules = {
  Captcha:{
    required: true
  },
  AvdrConditions:{
    required: true
  },
  FirstName: {
    required: true
  },
  GcFunction: {
    required: true
  },
  FirstLetters: {
    required: true
  },
  LastName: {
    required: true
  },
  Function: {
    required: true
  },
  Email: {
    required: true,
    email: true
  },
  Phone: {
    required: true,
    digits: true,
    minlength: 10
  }
};
// Error messages
const formErrorMessages = {
  FirstName: {
    required: "Dit veld is verplicht"
  },
  GcFunction: {
    required: "Dit veld is verplicht"
  },
  FirstLetters: {
    required: "Dit veld is verplicht"
  },
  LastName: {
    required: "Dit veld is verplicht"
  },
  Function: {
    required: "Dit veld is verplicht"
  },
  Phone: {
    required: "Dit veld is verplicht",
    digits: "Alleen cijfers toegestaan (gebruik 06)",
    minlength: 'Minimaal 10 tekens'
  },
  Password: {
    required: "Dit veld is verplicht",
    minlength: 'Minimaal 8 tekens',
  },
  PasswordAgain: {
    required: "Dit veld is verplicht",
    minlength: 'Minimaal 8 tekens',
    equalTo: 'Wachtwoorden komen niet overeen'
  },
  Email: {
    email: 'Vul een geldig email in',
    required: "Dit veld is verplicht"
  },
  EmailAgain: {
    equalTo: 'Email komt niet overeen',
    required: "Dit veld is verplicht",
    email: 'Vul een geldig email in'
  },
  CompanyName: {
    required: "Dit veld is verplicht"
  },
  CompanyAddress: {
    required: "Dit veld is verplicht"
  },
  CompanyPostcode: {
    required: "Dit veld is verplicht"
  },
  CompanyPlace: {
    required: "Dit veld is verplicht"
  },
  AvdrConditions: {
    required: "De voorwaarden zijn verplicht"
  },
  InvoiceEmail: {
    email: 'Vul een geldig email in',
    required: "Dit veld is verplicht"
  },
  InvoiceAscription: {
    required: "Dit veld is verplicht"
  },
  InvoiceAddress: {
    required: "Dit veld is verplicht"
  },
  InvoicePostalCode: {
    required: "Dit veld is verplicht"
  },
  InvoiceCity: {
    required: "Dit veld is verplicht"
  }
};

const honformErrorMessages = {
  HonorariumREK: {
    minlength: 'Uw IBAN is niet juist, minimaal 18 tekens.'
  },
  AvdrConditions: {
    required: "De voorwaarden zijn verplicht"
  },
  // Password: {
  //   required: "Dit veld is verplicht",
  //   minlength: 'Minimaal 8 tekens',
  // },
  // PasswordAgain: {
  //   required: "Dit veld is verplicht",
  //   minlength: 'Minimaal 8 tekens',
  //   equalTo: 'Wachtwoorden komen niet overeen'
  // },
  CurriculumVitae: {
    maxlength: 'maximaal 300 characters'
  }
};

// -----------------------------------------------------------------------
//
// Application of form validation
//
// -----------------------------------------------------------------------

const $courseRegisterForm = $("form#Form_registerCourseForm");
const $personalDetailsForm = $("form#Form_PersonalDetailsForm");
const $personalDetailsPasswordForm = $("form#Form_personalPasswordDetailsForm");
const $registerForms = $("form[id$='RegisterForm'], form[id$='Form_OnlyRegisterFormLight']");
const $honoform = $("#Form_detailsForm");
const $registerAndUpgradeCourseForm = $("#Form_registerAndUpgradeCourseForm");


$registerAndUpgradeCourseForm.validate({
  rules: divergentInvoiceDataRules,
  messages: formErrorMessages
});


$courseRegisterForm.validate({
  rules: divergentInvoiceDataRules,
  messages: formErrorMessages
});

$honoform.validate({
  rules: {
    HonorariumREK: {
      minlength: 18
    },
    CurriculumVitae: {
      maxlength: '300',
    },
    // Password: {
    //   required: true,
    //   minlength: 8
    // },
    // PasswordAgain: {
    //   required: true,
    //   minlength: 8,
    //   equalTo: `#Form_detailsForm_Password`
    // }
  },
  messages: honformErrorMessages
});

$personalDetailsPasswordForm.validate({
  rules: {
    ...registerFormRules,
    ...divergentInvoiceDataRules,
    Password: {
      minlength: 8
    },
    PasswordAgain: {
      required: {
        depends: () => $('#Form_personalPasswordDetailsForm_Password').is(':filled')
      },
      equalTo: '#Form_personalPasswordDetailsForm_Password',
      minlength: 8
    }
  },
  messages: formErrorMessages
})

$personalDetailsForm.validate({
  rules: {
    ...registerFormRules,
    ...divergentInvoiceDataRules,
    Password: {
      minlength: 8
    },
    PasswordAgain: {
      required: {
        depends: () => $('#Form_PersonalDetailsForm_Password').is(':filled')
      },
      equalTo: '#Form_PersonalDetailsForm_Password',
      minlength: 8
    }
  },
  messages: formErrorMessages
})

$registerForms.each((_, f) => {
  const $form = $(f);
  const $formID = $form[0].id;

  const registerExtraFields = {
    EmailAgain: {
      required: true,
      email: true,
      equalTo: `#${$formID}_Email`
    },
    Password: {
      required: true,
      minlength: 8
    },
    PasswordAgain: {
      required: true,
      minlength: 8,
      equalTo: `#${$formID}_Password`
    }
  }

  $form.validate({
    rules: {
      ...registerFormRules,
      ...divergentInvoiceDataRules,
      ...registerExtraFields
    },
    messages: formErrorMessages
  });
});

// -------------------------------------------------
// Hide divergent invoice data related fields if the checkbox is ticked off and vice versa.
// -------------------------------------------------

// Checkbox inputs ending with DivergentInvoiceData in id.
const $divergentInvoiceDataContainer = $('.divergent-invoice-container');

if ($divergentCheckbox.length > 0 && $divergentInvoiceDataContainer.length > 0) {

  if ($divergentCheckbox[0].checked) {
    $divergentInvoiceDataContainer.toggleClass('show', $divergentCheckbox[0].checked);
  }

  $divergentCheckbox.on('change', (e) => {
    $divergentInvoiceDataContainer.toggleClass('show', e.target.checked);
  });
}

/**
 * speaker dashboard form
 * show warning popup when user attemps to leave page with unsaved changes.
 */
const $speakerDashboardForm = $('.dashboard__form').find('.form');

$(document).ready(function () {
  if ($speakerDashboardForm.length > 0) {
    let originalForm = $speakerDashboardForm.serialize();

    $speakerDashboardForm.submit(function () {
      window.onbeforeunload = null
    })

    window.onbeforeunload = function () {
      if ($speakerDashboardForm.serialize() != originalForm)
        return 'Are you sure you want to leave?'
    }
  }
})
