import slick from 'slick-carousel';

$(document).ready(function () {
  var stHeight = $('.slick-track').height();
  $('.slick-slide').css('height', stHeight + 'px');

  const BaseHref = $('base').attr('href');


  if (
    $('body').hasClass('SubscriptionPage') ||
    $('body').hasClass('ThirdPartyPage') ||
    $('body').hasClass('LearningPage')  ||
    $('body').hasClass('OnDemandSubscriptionPage') ||
    $('body').hasClass('CursuslibaryPage') ||
    $('body').hasClass('CursusPage') ||
    $('body').hasClass('AvdrNews') ||
    $('body').hasClass('SpeakerPage')
  ){
      var initWidth = ($('.main').width());
      var initSidebarWidth = ($('.related-posts').width());
      var reviewSlider = ($('.main').width());

      $(".newpageslider .slider-container, .newpageslider .slick-dots").css({
        "maxWidth": initWidth - 40
      });

      $(".group-advertisement-slider").css({
        "maxWidth": initSidebarWidth - 1
      });
      $(".quoteholderUp").css({
        "maxWidth": reviewSlider - 40
      });


      window.addEventListener("resize", myresizeFunction);


      // if (initWidth < 860){
      //   $(".grid-item img, .slick-slide").css({
      //     "width": initWidth - 40
      //   });
      // }

      function myresizeFunction() {
        $(".newpageslider").hide();
        $(".group-advertisement-slider").hide();
        $(".quoteholderUp").hide();

        var newWidth = ($('.main').width());
        $(".newpageslider .slider-container, .newpageslider .slick-dots").css({
          "maxWidth": newWidth - 40
        });
        var newSidebarWidth = ($('.related-posts').width());
        $(".group-advertisement-slider").css({
          "maxWidth": newSidebarWidth - 1
        });
        var reviewSlider = ($('.main').width());
        $(".quoteholderUp").css({
          "maxWidth": reviewSlider - 40
        });

        $(".newpageslider").show();
        $(".group-advertisement-slider").show();
        $(".quoteholderUp").show();
      }
  }



  $('#advertisement-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });


  $('.mytraveldashboardbanner').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });



  $('#group-advertisement-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    // variableWidth: true
  });

  if ($("body").hasClass("OnDemandSubscriptionPage")) {
    $('#gallery').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      mobileFirst: true,
      dots: true,
      infinite: false,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },

      ]
    });
  }
  else{
  $('#gallery').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },

    ]
  });
}
  $('.clubspeakerslider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    ]
  });


  $('.quoteholder').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
  });



  $('.clubalumnispeakerslider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    ]
  });

  $('.clubalumnialbumslider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    ]
  });

  $('.portfoliomagazineslider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    ]
  });

  if ($('.clubspeakerslider .slick-slide').length > 2) {
    $('.clubspeakerslider').slick('slickSetOption', 'rows', 2);
    $('.clubspeakerslider').slick('refresh')
  }

  if ($('.clubalumnispeakerslider .slick-slide').length > 3) {
    $('.clubalumnispeakerslider').slick('slickSetOption', 'rows', 3);
    $('.clubalumnispeakerslider').slick('refresh')
  }
  if ($('.clubalumnialbumslider .slick-slide').length > 1) {
    $('.clubalumnialbumslider').slick('slickSetOption', 'rows', 1);
    $('.clubalumnialbumslider').slick('refresh')
  }

  if ($('.portfoliomagazineslider .slick-slide').length > 2) {
    $('.portfoliomagazineslider').slick('slickSetOption', 'rows', 1);
    $('.portfoliomagazineslider').slick('refresh')
  }


  $('.clubspeakersliderLarge').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    ]
  });

  if ($('.clubspeakersliderLarge .slick-slide').length > 4) {
    $('.clubspeakersliderLarge').slick('slickSetOption', 'rows', 4);
    $('.clubspeakersliderLarge').slick('refresh')
  }

  $('.clubspeakers').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
      {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    ]
  });


  $('.course__list_annotation').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
      {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    ]
  });




  $('.specialspeakerslider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    ]
  });

  if ($('.specialspeakerslider .slick-slide').length > 2) {
    $('.specialspeakerslider').slick('slickSetOption', 'rows', 2);
    $('.specialspeakerslider').slick('refresh')
  }


  $('#companyslider_GC').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 373,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },{
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },

    ]
  });




  $('#companyslider').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 373,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },{
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },

    ]
  });
  $('#gallery-portfolio-slider').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    variableWidth: true,
    mobileFirst: true,
  });

  $('#column-new-gallery-slider').slick({
    arrows: false,
    dots: true,
    infinite: false,
    autoplay: false,

    // autoplay: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: false,
    // variableWidth: true,
    centerMode: false,
    responsive: [
      {
      breakpoint: 390,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      }
    }
    ,{
      breakpoint: 1640,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
      {
      breakpoint: 1250,
      settings: {
        variableWidth: false,
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
      {
      breakpoint: 860,
      settings: {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    ]
  });


  $('#new-gallery-slider').slick({
    arrows: false,
    dots: true,
    infinite: false,
    // autoplay: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    // variableWidth: false,
    variableWidth: true,
    centerMode: false,
    responsive: [
      {
      breakpoint: 390,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      }
    }
    ,{
      breakpoint: 1640,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },
      {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
      {
      breakpoint: 860,
      settings: {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    ]
  });

  $('#column-new-gallery-slider-four').slick({
    arrows: false,
    dots: true,
    infinite: true,
    // autoplay: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    // variableWidth: false,
    variableWidth: true,
    centerMode: false,
    responsive: [
      {
      breakpoint: 390,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      }
    }
    ,{
      breakpoint: 1640,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },
      {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
      {
      breakpoint: 860,
      settings: {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    ]
  });
  // var initslideWidth = ($('.slick-slide').first().width() * 2);
  // $(".slick-slide").first().css({
  //   "width": initslideWidth
  // });

  // alert(initslideWidth);

  // window.onresize = function() {
  //   var newWidth = ($('.slick-slide').first().width() * 2);
  //   $(".slick-slide").first().css({
  //     "width": newWidth
  //   });
  // }

  $('#gallery-albums-slider').slick({
    arrows: false,
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: false,
    centerMode: false,
    responsive: [
      {
      breakpoint: 390,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      }
    }
    ,{
      breakpoint: 1640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
      {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
      {
      breakpoint: 860,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    ]
  });

  $('#magazine-slider').slick({
    arrows: false,
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        }
      }
      ,{
        breakpoint: 1640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });

  $('#video-slider').slick({
    arrows: false,
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        }
      }
      ,{
        breakpoint: 1640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });
  $('.slider--two').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    }]
  });


  $('.slider--two-row').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    dots: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  });


  if ($(window).width() > 768) {
    if ($('.slider--two-row .slick-slide').length > 2) {
      $('.slider--two-row').slick('slickSetOption', 'rows', 2);
      $('.slider--two-row').slick('refresh')
    }

    if ($('.slider--two-row .slick-slide').length > 8) {
      $('.slider--two-row').slick('slickSetOption', 'rows', 8);
      $('.slider--two-row').slick('refresh')
    }
  }



  $('#speakers').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },

    ]
  });

  $('#masterclassspeakers').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },

    ]
  });




  $('#reviews').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },

    ]
  });


  $('#newreviews').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },

    ]
  });

  $('.books--big').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: true,
    prevArrow:
      `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-blue.svg"></button>`,
    nextArrow:
      `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-blue.svg"></button>`,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
      }
    },
    ]
  });


  if ($('.rootnew').length > 0) {

    $('.slider__wrapper.books--small').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      mobileFirst: true,
      dots: true,
      infinite: true,
      prevArrow:
        `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-blue.svg"></button>`,
      nextArrow:
        `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-blue.svg"></button>`,
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }

      ]
    });
  }


  $('#books-releases').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    mobileFirst: true,
    dots: true,
    infinite: true,
    // prevArrow:
    // `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-white.svg"></button>`,
    // nextArrow:
    // `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-white.svg"></button>`,
    // responsive: [{
    //   breakpoint: 540,
    //   settings: {
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    //   }
    // },
    // {
    //   breakpoint: 660,
    //   settings: {
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    //   }
    // },
    // {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 2,
    //     }
    //   },
    //   {
    //     breakpoint: 1300,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 2,
    //     }
    //   },
    //   {
    //     breakpoint: 1400,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 2,
    //     }
    //   },
    // ]
  });

  $('.books-other').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    mobileFirst: true,
    dots: false,
    infinite: true,
    prevArrow:
      `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-white.svg"></button>`,
    nextArrow:
      `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-white.svg"></button>`,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        }
      },
    ]
  });

  $('.books-other--magazine').slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    mobileFirst: true,
    dots: false,
    infinite: true,
    prevArrow:
      `<button class="slick-prev slider__controls slider__prev"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-left-white.svg"></button>`,
    nextArrow:
      `<button class="slick-next slider__controls slider__next"><img src="${BaseHref}_resources/themes/avdr/images/icons/arrow-right-white.svg"></button>`,
    responsive: [{
      breakpoint: 540,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },
    ]
  });

  $('#magazines').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },

    ]
  });


  $('#lf-magazines').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },

    ]
  });


  $('#videos').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    dots: true,
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    ]
  });

  $('.lf-masonary').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    dots: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    ]
  });
});
